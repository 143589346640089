@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: CarlMarx;
  src: url(./assets/fonts//39068.otf);
}

@font-face {
  font-family: Grifo;
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/Grifo/GrifoTesteM-Regular.otf)
}

@font-face {
  font-family: Grifo;
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/Grifo/GrifoTesteS-Medium.otf)
}

@font-face {
  font-family: Grifo;
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/Grifo/GrifoTesteM-Bold.otf)
}

:root {
  --main-background-color: #F9F6F2;
  --header-height: 130px;
}

span {
  line-height: 28px;
  font-size: 16;
}

li {
  line-height: 28px;
  font-size: 16;
}




body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background-color);
  overflow-x: hidden;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.circular-button {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;
}


.circular-button-dark:hover {
  cursor: pointer;
  transform: scale(1.1, 1.1);
  box-shadow: 0px 0px 12px 2px #000000;
  transition: all 0.3s ease-in-out;
}


.circular-button:hover {
  cursor: pointer;
  transform: scale(1.1, 1.1);
  box-shadow: 0px 0px 12px 2px #ADADAD;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.circular-button::after {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.cursor-circle {
	position: absolute;
  border: solid 2px #ccc;
	width: 60px; 
	height: 60px; 
  border-radius: 50%;  
  z-index: 1000;
  -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
} 

.cursor-circle-dark {
	position: absolute;
  border: solid 2px #000;
	width: 60px; 
	height: 60px; 
  border-radius: 50%;  
  z-index: 1000;
  -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
} 

