.main-container {
  display: flex;
  margin: 200px 200px 200px 200px;
}

.main-container .column-1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.main-container .column-2 {
  flex: 1;
  padding-bottom: 68px;
}

.sketchbook-title {
  font-size: 24px;
  font-weight: 600;
}

.projects-button {
  width: 144px;
  height: 144px;
  background-color: #000000;
  border-radius: 80px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*    */


/* Container for the columns */
.columns-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top: 12px;
}

/* Style for each column */
.column {
  /* padding: 15px; */
  border-radius: 5px;
}

/* List styles */
ul {
  list-style-type: none;
  padding: 0;
}

li {
  line-height: 1.3rem;
  font-size: 1rem;
}

/* Media query to stack columns on smaller screens */
@media (max-width: 768px) {
  .columns-container {
    grid-template-columns: 1fr; /* Stack the columns vertically */
  }
}