
.app-logo {
    height: 47px;
    width: 47px;
    border-radius: 30px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

.app-logo:hover {
    transform: scale(1.1, 1.1);
    box-shadow: 0px 0px 12px 2px #ADADAD;
    transition: all 0.3s ease-in-out;
}

.app-logo::after {
    transition: all 0.3s ease-in-out;
}

.logo-container {
    flex: 1;
}
  
.app-header {
    height: var(--header-height);
    margin-bottom: calc(-1 * var(--header-height));
    display: flex;
    padding-left: 97px;
    padding-right: 97px;
    flex-direction: row;
    align-items: center;
    z-index: 999;
}

.menu {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.menu-item {
    padding-left: 9px;
    padding-right: 30px;
    font-size: 14px;
    color: black;
    text-decoration: none;
}

.light {
    color: white;
}

.menu-item:hover {
    cursor: pointer;
    font-weight: 500;
    z-index: 999;
}

.menu-item.last {
    padding-right: 0px;
}

.app {
    font-family: 'Montserrat';
    position: relative;
}