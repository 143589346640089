.atmin-root {
  background-color: black;
}

.project-container {
  display: flex;
  margin: 152px 152px 100px 152px;
    /* padding-bottom: 68px; */
}


@keyframes load_up{
  from{opacity:0;top:100%}
  to{opacity:1;top:0%;}
  
}@-webkit-keyframes load_up{
  from{opacity:0;top:100%}
  to{opacity:1;top:0%;} 
}

.design-process-container {
  display: flex;
  margin: 0px 152px 87px 152px;
  align-items: center;
}

.background-color-transition {
  -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s;
}

.design-process-step {
  height: 50px;
  color:#80F3CA;
  font-size: 18px;
  margin-bottom: 16px;
}

.design-process-number {
  margin-right: 8px;
}

.design-process-substep { 
  color: #DADADA;
  font-size: 18px;
  margin-bottom: 16px;
}

.project-step-title {
  font-size: 24px;
  color: #B59250;
  font-weight: 600;
}

.more-projects-container {
  display: flex;
  margin: 0px 152px 87px 152px;
  align-items: center;
}
.more-projects-container .section-title {
  font-size: 24px;
}

.section-title {
  font-weight: 600;
  font-size: 36px;
  color: #E0BE74;
}

.section-line { 
  flex: 1;
  height: 0.5px;
  background-color: #E0BE74;
  margin-left: 45px;
}


.project-page-title {
  font-size: 64px;
  color: #E0BE74;
  font-style: italic;
}

.project-page-subtitle {
  color: white;
  font-size: 18px;
  font-weight: 300;
}

.project-page-description {
  color: white;
  font-size: 24px;
  font-weight: 400;
  margin-top: 96px;
  width: 80%;
}

.project-container .column-1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-main-image {
  z-index: 1;
}


.project-main-image.instacart {
  height: 25vw;
  min-height: 255px;
  max-width: 100vw;
}

.project-main-image.naturally {
  height: 35vw;
  min-height: 255px;
  max-width: 100vw;
}

.project-main-image.noesis {
  height: 35vw;
  min-height: 310px;
  max-width: 100vw;
}

.project-main-image.off2class {
  height: 25vw;
  min-height: 190px;
  max-width: 100vw;
  border-radius: 15px;
}

.project-main-image.atmin {
  height: 30vw;
  min-height: 290px;
  max-width: 100vw;
}

.atmin-project-background-circle {
  background-color: #80F3CA;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vw;
  width: 30vw;
  min-width: 290px;
  min-height: 290px;
}

/* .atmin-project-background-circle {
  border-radius: 200px;
  height: 370px;
  width: 370px;
  margin-left: -600px;
  background-color: #80F3CA;
} */

.column-2 {
  width: 50%;
}

.sketchbook-title {
  font-size: 24px;
  font-weight: 600;
}

.projects-button {
  width: 144px;
  height: 144px;
  background-color: #000000;
  border-radius: 80px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
