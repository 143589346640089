.main-container {
  display: flex;
  margin: 200px;
}

.sketchbook-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding-top: 68px;
  /* justify-content: center; */
  /* align-items: center; */
}

.sketchbook-image-container {
  display: flex;
  position: relative;
  margin-bottom: 47px;
}

.sketchbook-image {
  width: 672px;
  height: auto;
}

.sketchbook-text-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  text-align: right;
}

.sketchbook-images {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sketchbook-title {
  font-size: 36px;
  font-weight: 600;
  color: #623D2E;
}

.projects-button {
  width: 144px;
  height: 144px;
  background-color: #000000;
  border-radius: 80px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
