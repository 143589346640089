.sideNav {
    position: fixed;
    left: 20px; /* Adjust to fit your needs */
    top: 50%;
    transform: translateY(-50%); /* Centers the nav vertically */
    //background-color: #f4f4f4; /* Background for the nav */
    padding: 10px;
    border-radius: 8px;
    z-index: 999999;
  }
  
  .sideNav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sideNav li {
    margin: 10px 0;
    text-align: left;
  }
  
  .sideNav button {
    background-color: transparent;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 1em;
    text-decoration: none;
    transition: font-size 0.3s ease-in-out, color 0.3s ease-in-out; /* Transition for font-weight and color */
  }
  
  .sideNav button:hover {
    color: #2980b9;
  }
  
  .sideNav button:focus {
    outline: none;
  }