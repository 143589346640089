.guestArea {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #623d2e60;
  }
  
  .modal {
    background-color: #fff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    width: 400px;
    max-width: 50%;
    /* text-align: center; */
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  h2 {
    color: #333;
    font-weight: bold;
    font-family: 'Grifo';
    font-size: 2.2rem;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 14px;
    color: #777;
    margin-bottom: 12px;
  }
  
  .requestAccess {
    display: block;
    margin-bottom: 40px;
    font-size: 12px;
    color: #555;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .passwordInput {
    padding: 10px;
    border: 1px solid #623d2e;
    background-color: #f5ecde;
    width: 60%;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 20px;
    font-size: 12px;
  }
  
  .submitButton {
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .submitButton:hover {
    background-color: #333;
  }
  