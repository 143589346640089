
/* SECTIONS */


.app {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}


/* Section 1 */

.home-section-1 {
  height: 100vh;
  margin-left: 20%;
}

.home-section-1 .name {
  font-size: 24px;
  font-weight: 400;
  color: #623D2E;
  margin-bottom: 16px;
  padding-top: 35vh;
}

.home-section-1 .main-title {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-size: 64px;
}

.home-section-1 .main-title.second {
  padding-left: 88px;
}

/* Section 1  END*/

/* Section 2 */

.project-clickable-section {
  text-decoration: none;
  color: black;
}

.selected-projects-section {
  background-color: #E8E1D6;
}

.selected-projects-section .title-container {
  padding-top: 95px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 60px;
}

.selected-projects-section .title-container .title { 
  flex: 1;
  margin-left: 10%;
  margin-right: 37px;
  font-weight: 600;
  font-size: 36px;
  color: #623D2E;
}

.selected-projects-section .title-container .line { 
  flex: 3;
  height: 0.5px;
  background-color: #623D2E;
}

.highlighted-project {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content:center;
  margin-bottom: 85px;
}

.highlighted-project-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.highlighted-project-title {
  font-size: 36px;
  font-weight: 600;
}

.highlighted-project-year {
  font-size: 18px;
  font-weight: 400;
}

.highlighted-project-image-container {
  display: flex;
  align-content: center;
}

.project-image {
  z-index: 1;
}

.project-image.atmin {
  height: 447px;
}

.atmin-background-circle {
  border-radius: 200px;
  height: 370px;
  width: 370px;
  margin-left: -400px;
  background-color: #80F3CA;
}

.project-image.instacart {
  height: 272px;
}

.project-image.oralbrooks {
  height: 272px;
}

.instacart-background-square {
  height: 328px;
  width: 328px;
  margin-right: -328px;
  background-color: #F88816;
}

.oralbrooks-background-circle {
  border-radius: 200px;
  height: 328px;
  width: 328px;
  margin-left: -346px;
  background-color: #193935;
}

.projects-row {
  display: flex;
  padding-bottom: 100px;
}

.project {
  display: flex;
  flex: 1;
  height: 380;
  align-items: center;
  justify-content: center;
}

.project-inner-container {
  display: flex;
  flex-direction: column;
}

.project-image-container {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.project-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-title {
  font-size: 24px;
  font-weight: 600;
}

.project-year {
  font-size: 18px;
  font-weight: 400;
}
/* Section 2 END */


/* Sketchbook Section */

.sketchbook-text-company {
  font-weight: 700;
}

.sketchbook-button {
  width: 144px;
  height: 144px;
  background-color: #000000;
  border-radius: 80px;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sketchbook-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 230px;
  padding-bottom: 230px;
}

.sketchbook-section-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}

.sketchbook-section-text-container {
  width: 450px;
  margin-right: 200px;
}

.sketchbook-section-text {
  font-size: 24px;
}

/*  Sketchbook section END */



/* SECTIONS END */
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
